import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import {getEnvironmentVariable} from 'Utility/document';

const PRODUCTION_ENVIRONMENTS: ('production' | 'staging' | 'master')[] = [
  'production',
  'staging',
  'master',
];

const ALLOWED_TRACING_URLS = [
  new RegExp(window.location.origin),
  /^https:\/\/([a-z0-9-]+\.)+dealmaker\.tech/,
];

export const initDatadog = (): void => {
  // Initializer for tracking browser logs sent to DataDog, can be found at https://app.datadoghq.com logs sidebar, frontend service
  if (
    PRODUCTION_ENVIRONMENTS.includes(
      getEnvironmentVariable('env') as 'production' | 'staging' | 'master'
    )
  ) {
    datadogLogs.init({
      clientToken: getEnvironmentVariable('SVELTE_APP_DATADOG_TOKEN'),
      site: 'datadoghq.com',
      env: getEnvironmentVariable('env'),
      service: 'dm-app-frontend',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });

    // Initializer for tracking browser performance sent to DataDog, can be found at https://app.datadoghq.com/rum/
    datadogRum.init({
      applicationId: getEnvironmentVariable(
        'SVELTE_APP_DATADOG_RUM_APPLICATION_ID'
      ),
      clientToken: getEnvironmentVariable(
        'SVELTE_APP_DATADOG_RUM_CLIENT_TOKEN'
      ),
      site: 'datadoghq.com',
      sessionSampleRate: 60,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      service: 'dm-app-rum',
      env: getEnvironmentVariable('env'),
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: ALLOWED_TRACING_URLS,
      traceSampleRate: 20,
    });

    // Start recording session replays
    datadogRum.startSessionReplayRecording();
  }
};
